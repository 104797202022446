@import '../../../styles/variables.scss';

.search-bar-container {
  margin-left: 0.5rem;
  width: 100%;

  .search-bar-icon {
    color: #b0b0b3;
    font-size: 15px;
    transition: font-size 0.5s ease;
    display: inline-block;
    background-image: url("/assets/img/nav/search.png");
    background-repeat: no-repeat;
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    margin-left: -15px;
    position: relative;
  }

  .search-bar-input {
    padding-bottom: 8px;
    outline: 0;
    border-width: 0 0 1px;
    border-color: #c7c7c9fa;
    box-sizing: border-box;
    min-width: 10rem;
    height: 1.5rem;
    position: relative;
    transition: border-width 0.1s ease;
    transition-delay: 0.1s;

    &:focus {
      border-width: 0 0 2px;
      border-color: #919196fa;
    }
  }
}

#recent-activity-drawer {
  .search-bar-container {
    margin-left: 3rem;
    padding-top: 15px;
    font-size: $font-size-14;

    .search-bar-input {
      min-width: 18.5rem;
    }

    input#search-input {
      height: 33px;
      border-bottom: 1px solid #c7c7c9fa !important;
      box-sizing: border-box;
    }

    ::placeholder,
    #placeholder {
      font-size: $font-size-14;
      color: $light-grey !important;
      font-family: $biotif;
    }
  }
}
