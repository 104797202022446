@import '../../../styles/variables.scss';

.atsDropdown {
  .dropdown-menu {
    border-color: $button-bg !important;
    margin: 0 !important;

    &[aria-labelledby="sortBy-dropdown"] {
      max-height: 350px;
      position: fixed;
      overflow-y: auto;
    }
  }

  .atsDropdownButton,
  .atsDropdownButton:hover,
  .atsDropdownButton:active,
  .atsDropdownButton.dropdown-toggle,
  .atsDropdownButton.dropdown-toggle:active,
  .atsDropdownButton.dropdown-toggle:focus {
    background-color: $base-white !important;
    border: none;
    box-shadow: none;
    z-index: 99;
    vertical-align: middle;
    padding: 10px;
    font-family: $biotif-semi-bold;
    font-size: $font-size-14;
    letter-spacing: 0;

    &.border-0.dropdown-toggle::after {
      vertical-align: middle;
      color: $light-grey;
      font-size: $font-size-16;
    }
  }

  #maxDistance-dropdown {
    padding-right: 10px !important;
    padding-left: 0.75rem !important;
    margin-top: -5px;
  }

  #activityFilter-dropdown {
    padding-right: 5px !important;
    padding-left: 0 !important;
    font-size: $font-size-34;
    font-family: $biotif-semi-bold;

    &.border-0.dropdown-toggle::after {
      font-size: $font-size-30;
    }
  }

  .dropdown-item {
    width: auto;
    margin: 0 0.5rem;
    padding: 0.35rem 0.75rem;
    font-size: $font-size-14;
    align-items: center;

    a {
      color: $page-title;
    }

    &:not(.selected) {
      color: $page-title !important;
    }

    &:hover {
      color: $button-bg !important;
    }
  }

  .dropdown-menu[aria-labelledby$='Task'],
  .dropdown-menu[aria-labelledby$='Appointment'] {
    .dropdown-item {
      letter-spacing: unset;
      width: unset;
      margin: unset;
      padding: unset;
    }
  }
}

#note-entity {
  a.dropdown-item {
    border-bottom: 1px solid $field-border;
  }
}

#sortBy-dropdown {
  &::after {
    display: none;
  }
}

.atsDropdown.viewTypeSwitch,
.atsDropdown.header-more-actions {
  .dropdown-toggle::before {
    display: inline-block;
    content: ' ';
    background-repeat: no-repeat;
  }
}

#detail {
  #details-title-wrapper {
    #details-title {
      .atsDropdown.header-more-actions {
        position: absolute;
        margin-left: 10px;
        display: inline;

        .dropdown-toggle::after {
          display: none !important;
        }

        .dropdown-toggle::before {
          background-size: 44px 44px;
          width: 44px;
          height: 44px;
          border-top: 0;
          background-image: url('/assets/img/entityHeaders/more-actions.png');
        }

        &.show {
          .dropdown-toggle::before {
            background-image: url('/assets/img/entityHeaders/more-actions-show.png');
          }
        }

        .dropdown-menu {
          position: fixed !important;
          z-index: 3;
          background-color: #4948ac;
          border: 1.5px solid $dark-grey !important;

          .dropdown-item {
            &:not(.selected) {
              color: rgba(255, 255, 255, 0.75) !important;
            }

            &:hover,
            &:focus {
              color: $base-white !important;
              background-color: $light-purple;
            }
          }
        }
      }
    }
  }
}
