#submission-stages {
  width: 100%;
  height: auto;

  .stages-container {
    .rectangle {
      fill: #f3f4f8;
    }

    .text {
      fill: #4d536d;
    }

    &.done {
      .rectangle {
        fill: #9399b2;
      }

      .text {
        fill: #ffff;
      }
    }

    &.active.select {
      .rectangle {
        fill: #2dcaff;
      }

      .text {
        fill: #ffff;
        font-family: "Biotif Semi Bold", "Biotif", sans-serif;
      }
    }

    &.active:hover {
      cursor: pointer;

      .rectangle {
        fill: #272668;
      }

      .text {
        fill: #ffff;
      }
    }

    &.inactive.select {
      .rectangle {
        fill: #4e536b;
      }

      .text {
        fill: #ffff;
        font-family: "Biotif Semi Bold", "Biotif", sans-serif;
      }
    }
  }
}
