@import '../styles/variables.scss';

.section-title {
  padding-bottom: 10px;
}

.ant-tabs-content::-webkit-scrollbar {
  width: 0 !important;
  background: transparent;
  display: none;
}

#table-search-bar-input {
  height: 38px;
}

.ant-input-search-button {
  height: 38px;
  border: unset;
}

.ant-input-search {
  border: 1px solid #d9d9d9;
  border-radius: 3px;

  &:hover,
  &:focus {
    border: 1px solid #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  .ant-input,
  .ant-input-group-addon {
    border: unset;

    &:hover,
    &:focus {
      border: unset !important;
      box-shadow: unset;
    }
  }
}

.invalid-email {
  color: #f2bc12 !important;
}

#details-tab {
  padding-top: 1rem;

  .ant-tabs-nav-wrap {
    font-size: $font-size-22 !important;
    font-family: $biotif-book !important;
    margin-bottom: 0 !important;
    height: var(--ant-tab-height) !important;
  }

  .ant-tabs {
    font-feature-settings: normal !important;
    font-variant: normal !important;

    &.ant-tabs-card {
      .ant-tabs-content {
        position: fixed;
        top: var(--ant-tab-top);

        @media only screen and (max-width: 1280px) {
          top: var(--ant-tab-min-top);
        }

        bottom: 0;
        overflow-y: auto !important;
        background-color: $base-offwhite;
        z-index: 1;
        -ms-overflow-style: none;  // IE 10+
        scrollbar-width: none;  // Firefox

        .ant-tabs-tabpane {
          padding: 2rem 0 1rem 3rem;
          background-color: $base-white;

          &.ant-tabs-tabpane-active {
            min-height: calc(100vh - var(--ant-tab-top) - var(--detail-padding));

            @media only screen and (max-width: 1280px) {
              min-height: calc(100vh - var(--ant-tab-min-top) - var(--detail-padding));
            }

            height: fit-content;
          }

          &.ant-tabs-tabpane-active:not(:nth-child(2)) {
            margin-bottom: var(--detail-padding);//2rem;
          }
        }

        .label,
        .value {
          font-size: $font-size-16;
          display: block;
        }

        .label {
          font-family: $biotif-semi-bold !important;
          color: $detail-label;
        }

        .value {
          font-family: $biotif;
          color: $dark-grey;
          margin: 0.25rem 0 1.5rem 0;
          padding: 0.25rem 0;

          .help-text {
            padding-left: 15px;
            color: $light-grey !important;
            font-family: $biotif;
            font-size: $font-size-14;
          }
        }

        #prescreen-edit {
          padding: 0 !important;
          margin-top: -55px !important;
          margin-right: 2rem;
        }

        #prescreen-read {
          margin-bottom: 1.5rem;
        }

        #resume-tab {
          #resume-object {
            min-height: var(--tab-content-fix-height) !important;
            height: var(--tab-content-fix-height) !important;

            @media only screen and (max-width: 1280px) {
              min-height: var(--tab-content-fix-height-sm) !important;
              height: var(--tab-content-fix-height-sm) !important;
            }

            overflow: hidden;

            #resume-embed {
              min-height: var(--tab-content-fix-height) !important;
              height: var(--tab-content-fix-height) !important;

              @media only screen and (max-width: 1280px) {
                min-height: var(--tab-content-fix-height-sm) !important;
                height: var(--tab-content-fix-height-sm) !important;
              }

              overflow: hidden;
            }
          }
        }
      }

      #submission-tab {
        min-height: var(--tab-content-fix-height) !important;
        height: var(--tab-content-fix-height) !important;

        @media only screen and (max-width: 1280px) {
          min-height: var(--tab-content-fix-height) !important;
          height: var(--tab-content-fix-height-sm) !important;
        }

        overflow: hidden;
      }

      .ant-tabs-nav {
        border-bottom: 0 !important;
        margin: 0;

        .ant-tabs-tab {
          background: transparent;
          min-width: 155px;
          text-align: center;
          color: $light-grey !important;
          border-radius: 0;
          border-color: $tab-border;
          border-style: solid;
          border-width: 1px 1px 1px 0;
          cursor: pointer;
          height: var(--ant-tab-height) !important;
          line-height: var(--ant-tab-height) !important;
          margin: 0;
          vertical-align: middle;
          font-size: $font-size-22 !important;
          font-family: $biotif-book !important;

          .ant-tabs-tab-btn {
            text-align: center;
            width: 100%;
          }

          .badge {
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            color: $light-grey;
            padding: 6px 0 8px 0;
            width: 30px;
            height: 30px;
            text-align: center;
            background-color: #e3e3e3;
            vertical-align: center;

            .fa-minus::before {
              color: $light-grey;
            }
          }

          &:first-child {
            border-width: 1px 1px 1px 1px;
          }

          &.ant-tabs-tab-active {
            background-color: $base-white !important;
            border-right: 1px solid $tab-border !important;
            border-left: 0;
            border-bottom: 0;

            .ant-tabs-tab-btn {
              color: $page-title !important;
            }

            .badge {
              color: $dark-grey;
              padding: 7px 0 8px 0;

              .fa-minus::before {
                color: $dark-grey;
              }
            }
          }
        }
      }
    }
  }

  /* start tab top border and badge color to match object color */
  &.jobs .ant-tabs.ant-tabs-card .ant-tabs-nav {
    .ant-tabs-tab:not(:nth-child(4)).ant-tabs-tab-active {
      border-top: 4px solid $jobs !important;

      .badge {
        background-color: $tab-circle-jobs;
      }
    }

    .ant-tabs-tab:nth-child(4).ant-tabs-tab-active {
      border-top: 4px solid $submissions !important;

      .badge {
        background-color: $submissions;
      }
    }

    .ant-tabs-tab:nth-child(5).ant-tabs-tab-active {
      border-top: 4px solid $placements !important;

      .badge {
        background-color: $tab-circle-placements;
      }
    }

    .ant-tabs-tab:nth-child(6).ant-tabs-tab-active {
      border-top: 4px solid $appointments !important;

      .badge {
        background-color: $appointments;
      }
    }
  }

  &.candidates .ant-tabs.ant-tabs-card .ant-tabs-nav {
    .ant-tabs-tab:not(:nth-child(6)).ant-tabs-tab-active {
      border-top: 4px solid $candidates !important;

      .badge {
        background-color: $candidates;
      }
    }

    .ant-tabs-tab:nth-child(6).ant-tabs-tab-active {
      border-top: 4px solid $submissions !important;

      .badge {
        background-color: $submissions;
      }
    }

    .ant-tabs-tab:nth-child(7).ant-tabs-tab-active {
      border-top: 4px solid $placements !important;

      .badge {
        background-color: $tab-circle-placements;
      }
    }

    .ant-tabs-tab:nth-child(8).ant-tabs-tab-active {
      border-top: 4px solid $appointments !important;

      .badge {
        background-color: $appointments;
      }
    }
  }

  &.contacts .ant-tabs.ant-tabs-card .ant-tabs-nav {
    .ant-tabs-tab:not(:nth-child(4)).ant-tabs-tab-active {
      border-top: 4px solid $contacts !important;

      .badge {
        background-color: $tab-circle-contacts;
      }
    }

    .ant-tabs-tab:nth-child(4).ant-tabs-tab-active {
      border-top: 4px solid $jobs !important;

      .badge {
        background-color: $tab-circle-jobs;
      }
    }

    .ant-tabs-tab:nth-child(5).ant-tabs-tab-active {
      border-top: 4px solid $placements !important;

      .badge {
        background-color: $tab-circle-placements;
      }
    }

    .ant-tabs-tab:nth-child(6).ant-tabs-tab-active {
      border-top: 4px solid $appointments !important;

      .badge {
        background-color: $appointments;
      }
    }
  }

  &.companies .ant-tabs.ant-tabs-card .ant-tabs-nav {
    .ant-tabs-tab:not(:nth-child(5)).ant-tabs-tab-active {
      border-top: 4px solid $companies !important;

      .badge {
        background-color: $companies;
      }
    }

    .ant-tabs-tab:nth-child(5).ant-tabs-tab-active {
      border-top: 4px solid $jobs !important;

      .badge {
        background-color: $tab-circle-jobs;
      }
    }

    .ant-tabs-tab:nth-child(6).ant-tabs-tab-active {
      border-top: 4px solid $placements !important;

      .badge {
        background-color: $tab-circle-placements;
      }
    }

    .ant-tabs-tab:nth-child(7).ant-tabs-tab-active {
      border-top: 4px solid $contacts !important;

      .badge {
        background-color: $tab-circle-contacts;
      }
    }

    .ant-tabs-tab:nth-child(8).ant-tabs-tab-active {
      border-top: 4px solid $appointments !important;

      .badge {
        background-color: $appointments;
      }
    }
  }

  &.placements .ant-tabs.ant-tabs-card .ant-tabs-nav {
    .ant-tabs-tab:not(:nth-child(4)).ant-tabs-tab-active {
      border-top: 4px solid $placements !important;

      .badge {
        background-color: $tab-circle-placements;
      }
    }
  }

  /* end tab top border and badge color to match object color */

  .details-field-col {
    vertical-align: top;
  }

  .edit {
    color: $light-grey;

    &:hover {
      color: $button-bg;
    }
  }

  .finished {
    color: $button-bg;
  }

  .edit::before,
  .finished::before {
    display: inline-block;
    content: '';
    background-repeat: no-repeat;
    background-size: 23px 22px;
    width: 23px;
    height: 22px;
  }

  .edit::before {
    background-image: url('/assets/img/inlineEdit/edit.png');
  }

  .edit:hover::before {
    background-image: url('/assets/img/inlineEdit/edit-hover.png');
  }

  .finished::before {
    background-image: url('/assets/img/inlineEdit/check-circle-indigo.png');
  }

  &.in {
    .ant-tabs {
      .ant-tabs-content {
        width: var(--inAntsTabWidth);

        .details-field-col {
          width: 50%;
          padding-right: 15px;
        }

        @media only screen and (min-width: 1085px) and (max-width: 1680px) {
          .tab-content {
            width: calc(var(--inTabWidth) * 0.55);
          }

          .details-field-col {
            width: 100%;
          }
        }

        @media only screen and (min-width: 1681px) and (max-width: 1920px) {
          .tab-content {
            width: calc(var(--inTabWidth) * 0.6);
          }
        }

        @media only screen and (min-width: 1921px) {
          .tab-content {
            width: calc(var(--inTabWidth) * 0.7);
          }
        }

        @media only screen and (max-width: 1084px) {
          width: calc(100vw - var(--global-nav-width) - var(--search-result-tile-width) - calc(var(--detail-padding) * 2));

          .details-field-col {
            width: 100%;
          }

          .tab-content {
            width: 100%;
          }
        }
      }
    }
  }

  &.out {
    .ant-tabs {
      .ant-tabs-content {
        width: var(--outAntsTabWidth);

        .details-field-col {
          width: 50%;
          padding-right: 14px;
        }

        @media only screen and (max-width: 768px) {
          width: calc(100vw - var(--global-nav-width) - var(--search-result-tile-out-width) - calc(var(--detail-padding) * 2));

          .details-field-col {
            width: 100%;
          }

          .tab-content {
            width: 100%;
          }
        }

        @media only screen and (min-width: 769px) and (max-width: 1280px) {
          .tab-content {
            width: calc(var(--outTabWidth) * 0.55);
          }

          .details-field-col {
            width: 100%;
          }
        }

        @media only screen and (min-width: 1281px) and (max-width: 1680px) {
          .tab-content {
            width: calc(var(--outTabWidth) * 0.65);
          }
        }

        @media only screen and (min-width: 1681px) and (max-width: 1920px) {
          .tab-content {
            width: calc(var(--outTabWidth) * 0.7);
          }
        }

        @media only screen and (min-width: 1921px) {
          .tab-content {
            width: calc(var(--outTabWidth) * 0.75);
          }
        }
      }
    }
  }

  &:not(.in):not(.out) {
    .ant-tabs {
      .ant-tabs-content {
        width: var(--individaulAntsTabWidth);

        .details-field-col {
          width: 50%;
          padding-right: 14px;
        }

        .tab-content {
          width: 100%;
        }

        @media only screen and (max-width: 768px) {
          width: calc(100vw - var(--global-nav-width) - calc(var(--detail-padding) * 2));

          .details-field-col {
            width: 100%;
          }
        }

        @media only screen and (min-width: 769px) and (max-width: 1280px) {
          .tab-content {
            width: calc(var(--individaulTabWidth) * 0.55);
          }

          .details-field-col {
            width: 100%;
          }
        }

        @media only screen and (min-width: 1281px) and (max-width: 1680px) {
          .tab-content {
            width: calc(var(--individaulTabWidth) * 0.65);
          }
        }

        @media only screen and (min-width: 1681px) and (max-width: 1920px) {
          .tab-content {
            width: calc(var(--individaulTabWidth) * 0.7);
          }
        }

        @media only screen and (min-width: 1921px) {
          .tab-content {
            width: calc(var(--individaulTabWidth) * 0.75);
          }
        }
      }
    }
  }
}

#details-title-wrapper {
  background-color: $base-offwhite;
  position: sticky;
  top: 0;
  z-index: 10;

  #details-title {
    background-color: $detail-header-bg;
    height: var(--ph-height);
    padding: var(--ph-y-padding) 4.25rem var(--ph-y-padding) 1.5rem;
    overflow: hidden;
    color: $base-white;

    .line1,
    .line2 {
      white-space: nowrap !important;
      display: flex !important;
      align-items: center !important;
    }

    .line1 {
      height: 72px;
    }

    .line2 {
      margin-top: 8px;
      height: 49px;
    }

    .objectIdDisplay {
      font-size: $font-size-28;
      font-family: $biotif;
      color: $light-grey;
      padding: 0 1.5rem 0 1rem;
    }

    .label,
    .value {
      font-size: $font-size-22;
      font-family: $biotif;
      color: $light-grey;
    }

    .value {
      color: $base-white;
      text-wrap: normal;
    }

    #details-title-text {
      font-size: var(--pt-font-size);
      font-family: $biotif-semi-bold;
    }

    #status {
      padding-left: 33px;
    }

    .actions {
      margin-right: 15px;

      a:not(:last-child),
      span {
        margin-right: 1.5rem;
      }

      &.external-links {
        margin-right: -40px;
      }

      a.linkedin-search-link {
        margin-right: 0;
      }
    }

    @media only screen and (max-width: 1280px) {
      height: var(--ph-min-height);
      padding-top: var(--ph-y-padding-sm);
      padding-bottom: var(--ph-y-padding-sm);

      #details-title-text {
        font-size: var(--pt-min-font-size);
      }

      .line1 {
        height: 44px;
      }

      .line2 {
        margin-top: 0;
        height: 39px;
      }

      .objectIdDisplay {
        font-size: $font-size-22;
      }

      .value {
        font-size: $font-size-18;
      }
    }

    .edit-link::before,
    .download-link::before,
    .open-new-tab-link::before,
    .linkedin-search-link::before,
    .google-search-link::before {
      margin-left: 10px;
      display: inline-block;
      content: '';
      background-repeat: no-repeat;
      background-size: 44px 44px;
      width: 44px;
      height: 44px;
    }

    .edit-link:not(.no-edit)::before {
      background-image: url('/assets/img/entityHeaders/edit.png');
    }

    .download-link::before {
      background-image: url('/assets/img/entityHeaders/download.png');
    }

    .open-new-tab-link::before {
      background-image: url('/assets/img/entityHeaders/new-tab.png');
    }

    .linkedin-search-link::before,
    .google-search-link::before {
      background-size: 19px 19px;
      width: 19px;
      height: 19px;
    }

    .linkedin-search-link::before {
      background-image: url('/assets/img/entityHeaders/linkedin-link.png');
      margin-right: 3px;
    }

    .google-search-link::before {
      background-image: url('/assets/img/entityHeaders/google-link.png');
      margin-left: 3px;
    }

    #status .value::before,
    #mobile-phone .value::before,
    #primary-email .value::before,
    #company .value::before,
    #job-title .value::before,
    #businessUnitId .value::before,
    #companyType .value::before {
      //display: inline-block;
      content: '';
      background-repeat: no-repeat;
      background-size: 22px 22px;
      vertical-align: middle;
      width: 22px;
      height: 22px;
      margin-right: 10px;
      padding-left: 22px;
    }

    #status .value::before {
      background-image: url('/assets/img/entityHeaders/status.png');
    }

    #companyType .value::before {
      background-image: url('/assets/img/entityHeaders/status.png');
    }

    #mobile-phone .value::before {
      background-image: url('/assets/img/quickItemSubmission/phone.png');
    }

    #primary-email .value::before {
      background-size: 26px 22px;
      width: 26px;
      height: 22px;
      padding-left: 26px;
      background-image: url('/assets/img/entityHeaders/email.png');
    }

    #company .value::before {
      background-image: url('/assets/img/entityHeaders/company.png');
    }

    #job-title .value::before {
      background-image: url('/assets/img/entityHeaders/job.png');
    }

    #businessUnitId .value::before {
      background-image: url('/assets/img/entityHeaders/hierarchy.png');
    }
  }
}

#detail {
  padding: 14px;

  &.details {
    animation: detailsFadeInOut $animation-duration-long ease-in-out forwards;

    @-webkit-keyframes detailsFadeInOut {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 0.25;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes detailsFadeInOut {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 0.25;
      }

      100% {
        opacity: 1;
      }
    }
  }

  &.in {
    #details-title-wrapper {
      width: var(--inAntsTabWidth);
    }
  }

  &.out {
    #details-title-wrapper {
      width: var(--outAntsTabWidth);
    }
  }

  &:not(.in):not(.out) {
    #details-title-wrapper {
      width: var(--individaulAntsTabWidth);
    }
  }
}

@media only screen and (max-width: 504px) {
  .details.in {
    display: none !important;
  }
}

#inactive-user-checkbox {
  background-color: transparent;
  margin-bottom: 1rem;
}

#inactive-user-checkbox > .checkbox-container {
  margin-left: 0;
}

.phone-container {
  cursor: pointer;
}

.phone-icon {
  opacity: 0;
  transform: translateX(-50%);
  // background-color: $base-offwhite;
  padding: 4px 4px;
  border-radius: 5px;
  margin-left: 5px;
}

.phone-container:hover {
  .phone-number {
    color: $button-bg-hover;
  }

  .phone-icon {
    opacity: 1;
  }
}

.phone-border {
  border-radius: 50%;
  background-color: #28a745;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.fa {
  color: white;
}
