.ats-effective-date-picker {
  position: relative;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  .react-datepicker__input-container {
    //top: 1rem !important;
    margin-top: 1rem;
  }

  label.calendar-icon.form::after {
    top: 25px !important;
    right: 55% !important;
  }

  .effective-date-btn {
    position: absolute !important;
  }

  button#cancel-inline {
    right: 5rem !important;
  }

  button#save-inline {
    right: 0 !important;
  }
}
