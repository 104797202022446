@import '../../../styles/variables.scss';

.item {
  a {
    font-family: $biotif-semi-bold;
    font-size: $font-size-16;
  }

  font-family: $biotif-book;
  font-size: $font-size-14;
  color: $dark-grey;
}

#search-modal,
#note-entity,
#quick-search-items {
  .email::before,
  .otherEmail::before,
  .job::before,
  .phone::before,
  .homePhone::before,
  .workPhone::before,
  .stringEntityId::before,
  .vmsJobId::before,
  .vmsPlacementId::before,
  .bullhornId::before,
  .location::before,
  .status::before,
  .company::before {
    display: inline-block;
    content: '';
    margin-right: 0.5rem;
    background-repeat: no-repeat;
    position: relative;
    top: 2px;
  }

  .otherEmail::before,
  .email::before {
    background-image: url('/assets/img/quickItemSubmission/quickItem/email.png');
    background-size: 14px 10px;
    width: 14px;
    height: 12px;
  }

  .stringEntityId::before,
  .vmsJobId::before,
  .vmsPlacementId::before,
  .bullhornId::before,
  .homePhone::before,
  .workPhone::before,
  .phone::before {
    background-image: url('/assets/img/quickItemSubmission/phone.png');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }

  .location::before {
    background-image: url('/assets/img/quickItemSubmission/quickItem/location.png');
    background-size: 12px 14px;
    width: 12px;
    height: 14px;
  }

  .job::before {
    background-image: url('/assets/img/quickItemSubmission/job.png');
    background-size: 13px 14px;
    width: 13px;
    height: 14px;
  }

  .company::before {
    background-image: url('/assets/img/quickItemSubmission/company.png');
    background-size: 12px 14px;
    width: 12px;
    height: 14px;
  }

  .status::before {
    background-image: url('/assets/img/quickItemSubmission/quickItem/status.png');
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
  }
}

#quick-search-items {
  .item {
    div {
      line-height: 28px;

      &.circle {
        width: 15px;
        height: 15px;
      }
    }
  }
}
