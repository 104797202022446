@font-face {
  font-family: 'Biotif';
  src: url('/assets/fonts/Biotif.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Black Italic';
  src: url('/assets/fonts/Biotif-Black-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Black';
  src: url('/assets/fonts/Biotif-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Bold Italic';
  src: url('/assets/fonts/Biotif-Bold-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Bold';
  src: url('/assets/fonts/Biotif-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Book Italic';
  src: url('/assets/fonts/Biotif-Book-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Book';
  src: url('/assets/fonts/Biotif-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif ExtraBold Italic';
  src: url('/assets/fonts/Biotif-ExtraBold-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif ExtraBold';
  src: url('/assets/fonts/Biotif-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Light Italic';
  src: url('/assets/fonts/Biotif-Light-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Light';
  src: url('/assets/fonts/Biotif-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Medium Italic';
  src: url('/assets/fonts/Biotif-Medium-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Medium';
  src: url('/assets/fonts/Biotif-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Regular Italic';
  src: url('/assets/fonts/Biotif-Regular-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Biotif Semi Bold Italic';
  src: url('/assets/fonts/Biotif-Semi-Bold-Italic.otf')  format('opentype');
}

@font-face {
  font-family: 'Biotif Semi Bold';
  src: url('/assets/fonts/Biotif-Semi-Bold.otf') format('opentype');
}
