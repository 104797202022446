@import '../../../styles/variables.scss';

#select-template-modal {
  min-width: 60rem !important;

  #column-divider {
    position: relative;
    height: 25rem;
    border-right: 1px solid $field-border;
  }

  .template-list {
    height: 25rem !important;
    overflow-y: auto !important;
    -webkit-animation: fadeinout 2s ease-in-out forwards;
    animation: fadeinout 2s ease-in-out forwards;
    padding-left: 1rem;
    width: 49%;

    .template-item {
      cursor: pointer;
      color: #4948ac;
      margin-bottom: 0.5rem;
      margin-right: 2rem;

      &:hover {
        color: $vivid-blue;
        background-color: #f5faff;
      }
    }
  }

  .template-content {
    width: 50%;
    max-height: 25rem;
    overflow-y: auto !important;
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
  }

  .filter {
    .label {
      //padding-top: 0.8rem;
      width: 6rem;
      margin-right: 2rem;
    }

    #template-select-visibility {
      width: 16rem;
      height: 40px;
    }

    #template-add-btn {
      margin-left: 1rem;
      width: 85px !important;
      height: 47px !important;
    }
  }
}
