#edit-modal {
  #task-private {
    margin-top: 49px;

    #isPrivate {
      width: auto;
      position: absolute;
      top: 27px;
      left: 7rem;

      .checkbox-container {
        font-family: "Biotif", sans-serif;
        padding-left: 30px !important;
        margin-left: 0.5rem !important;
        margin-top: 22px;
        font-size: 16px;
      }
    }
  }

  .ats-date-picker {
    margin-top: 1rem;

    .calendar-icon::after {
      top: 50px !important;
    }
  }
}
