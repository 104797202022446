@import '../../styles/variables.scss';
@import '../../compoments/HolidayTheme/style.scss';

.login-wrapper,
.error-wrapper {
  padding-top: calc(((100vh - 284px - 125px) / 2));
  border-top: 5px solid var(--theme-button-color, $vivid-blue);
  width: 100%;
  height: calc(100vh - 70px);
  background-color: var(--theme-background, rgb(39, 38, 104));
}

.login-wrapper {
  &.onlySSO {
    padding-top: calc(((100vh - 284px) / 2));
  }

  .loginTitle {
    height: 60px;
    font-size: 47px;
    font-family: $biotif;
    color: $base-offwhite;
  }

  .loginSubTitle {
    font-size: 20px;
    font-family: $biotif;
    color: $base-offwhite;
  }

  .ats-brand {
    content: '';
    display: inline-block;
    background-image: url('/assets/img/nav/ats-brand.png');
    background-repeat: no-repeat;
    background-size: 52px 52px;
    width: 52px;
    height: 52px;
    padding-right: 68px;
    margin-top: 13px;
  }

  .forgot-password {
    text-align: right;
    margin-right: 15px;
    margin-top: 2rem;
    margin-bottom: 2rem;

    #forgot-password-link {
      color: var(--theme-footer-color, $light-purple) !important;
      font-family: $biotif-bold;
      font-size: $font-size-14;
    }
  }

  .switch-sso {
    text-align: center;
    margin-right: 15px;
    margin-top: 2rem;
    margin-bottom: 2rem;

    #switch-sso-link {
      color: var(--theme-footer-color, $light-purple) !important;
      font-family: $biotif-bold;
      font-size: $font-size-14;
    }
  }

  .reset-sent {
    text-align: left;
    margin-right: 15px;
    margin-top: 2rem;
    margin-bottom: 2rem;

    #reset-sent-link {
      color: var(--theme-footer-color, $light-purple) !important;
      font-family: $biotif-bold;
      font-size: $font-size-18;
    }
  }

  #email-login-button {
    width: 317px !important;
    height: 60px;
    background-color: var(--theme-button-color, #2dcaff);
    padding: 0 !important;
  }

  #microsoft-login-button {
    width: 317px !important;
    height: 60px;
    background-color: var(--theme-button-color, #2dcaff);
    text-transform: none;
    padding: 0 !important;

    .office-365 {
      content: '';
      position: relative;
      top: 3px;
      display: inline-block;
      background-image: url('/assets/img/office-365.png');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      width: 20px;
      height: 20px;
      padding-right: 30px;
    }

    .botton-name {
      padding-bottom: 5px;
    }
  }

  input {
    height: 60px;
    width: 317px !important;
  }

  .alert {
    &.ats-error {
      background-color: #f2f2f2 !important;
      color: $light-purple;
    }
  }

  .caret-left {
    margin-right: 0.5rem;
    font-size: 26px;
    color: $light-grey;
  }

  .caret-right {
    margin-left: 1rem;
    font-size: 22px;
    color: $light-grey;
  }

  .reset-password {
    .label {
      font-size: 18px;
      font-family: $biotif;
      color: $base-offwhite;
    }

    input {
      height: 60px;
      width: 500px !important;
    }

    .info {
      font-size: 16px;
      font-family: $biotif;
      color: $light-grey;
    }

    .error {
      font-size: 16px;
      font-family: $biotif;
      color: $field-border-error;
      white-space: pre-line;
    }

    button {
      width: 500px !important;
      height: 60px;
      background-color: $button-bg;
      padding: 0 !important;
    }
  }

  .was-validated {
    input:invalid {
      background-color: #f2dede;
    }
  }
}

.footer {
  position: relative;
  height: 70px;
  background: var(--theme-footer-background, #151529);

  .copy-right-info {
    position: absolute;
    top: 35%;
    left: 5rem;
    color: var(--theme-footer-color, #55557a);
  }

  .need-help-info {
    position: absolute;
    top: 35%;
    right: 5rem;
    color: var(--theme-footer-color, #55557a);
  }
}
