#merge-records-form-modal {
  min-width: 60rem;

  .entityTypeShortID {
    color: #4948ac;
  }
}

#merge-records-confirm-modal {
  min-width: 60rem;
}
