@import '../../../styles/variables.scss';

.ant-input-number {
  &.form-control {
    padding: 0 !important;
    border: 0;
  }

  &.ant-input-number-focused {
    border-color: $field-border-selected !important;
    box-shadow: none !important;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.ant-input-number-input {
  border: 1px solid $field-border !important;
  border-radius: 0.25rem;
  height: calc(2em + 0.85rem + 3.5px) !important;

  &:focus {
    border-color: $field-border-selected !important;
    cursor: pointer;
  }
}

form.was-validated {
  .ant-input-number-input {
    box-shadow: none !important;
    background-image: none !important;

    &:valid,
    &.is-valid {
      border: 1px solid $field-border;
      background-color: white;
      border-radius: 0.25rem;
    }

    &:invalid,
    &.is-invalid {
      border-color: $field-border-error !important;
      border-radius: 0.25rem;
    }
  }
}
