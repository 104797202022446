@import '../../../styles/variables.scss';

/* Customize the label (the container) */
.checkbox-container {
  display: block;
  cursor: pointer;
  user-select: none;
  margin: 0.75rem 0;
  position: relative;
  width: fit-content;
  font-family: $biotif;
  -ms-user-select: none;
  -moz-user-select: none;
  font-size: $font-size-16;
  -webkit-user-select: none;
  background-color: transparent;
  padding-left: 2rem !important;

  /* Hide the browser's default checkboxmark */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkboxmark */
  .checkboxmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid $black;
  }

  /* When the checkboxmark is checked, add a blue background */
  input:checked ~ .checkboxmark {
    background-color: #4948ac;
  }

  input:focus ~ .checkboxmark {
    outline: solid 1px #4948ac;
  }

  /* Create the checkboxmark/indicator (hidden when not checked) */
  .checkboxmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* Show the checkboxmark when checked */
  input:checked ~ .checkboxmark::after {
    display: block;
  }
}
