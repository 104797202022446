.badges {
  margin-left: 0.25rem;
  overflow: hidden;

  img {
    width: auto;
    margin-left: 0.25rem;
    height: 13px;

    &[alt] {
      font-size: 0;
    }
  }

  &.header {
    img {
      height: 26px !important;
    }
  }
}
