@import '../../../styles/variables.scss';

.rbt-token {
  color: $button-bg-hover !important;
  border-radius: 1rem !important;
  padding: 0.61rem 2.2rem 0.61rem 0.8rem !important;
  font-family: $biotif-semi-bold;
  background-color: $skill-tag-bg !important;

  .rbt-token-remove-button {
    font-size: $font-size-20 !important;
    width: 27px;
    right: inherit;

    .span {
      margin-left: 0.5rem;
    }
  }

  button.close {
    z-index: 0;

    &:hover {
      color: $field-border-error;
    }
  }
}

.rbt-input-multi {
  &.rbt-input-multi {
    height: auto !important;
    min-height: calc(2em + 0.85rem + 3.5px) !important;
  }

  &.focus {
    border-color: $field-border-selected !important;
    outline: 0;
    box-shadow: none !important;
  }

  .rbt-input-wrapper {
    input.rbt-input-main {
      padding-top: 5px !important;
      z-index: 0 !important;
    }
  }
}

.rbt-input.is-invalid {
  border-color: $field-border-error !important;
}

.rbt.recent {
  ul::before {
    padding: 20px;
    content: 'Recent';
    color: #999;
    text-transform: uppercase;
  }
}
