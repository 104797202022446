@font-face {
  font-family: 'Biotif';
  src: url("/assets/fonts/Biotif.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Black Italic';
  src: url("/assets/fonts/Biotif-Black-Italic.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Black';
  src: url("/assets/fonts/Biotif-Black.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Bold Italic';
  src: url("/assets/fonts/Biotif-Bold-Italic.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Bold';
  src: url("/assets/fonts/Biotif-Bold.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Book Italic';
  src: url("/assets/fonts/Biotif-Book-Italic.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Book';
  src: url("/assets/fonts/Biotif-Book.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif ExtraBold Italic';
  src: url("/assets/fonts/Biotif-ExtraBold-Italic.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif ExtraBold';
  src: url("/assets/fonts/Biotif-ExtraBold.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Light Italic';
  src: url("/assets/fonts/Biotif-Light-Italic.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Light';
  src: url("/assets/fonts/Biotif-Light.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Medium Italic';
  src: url("/assets/fonts/Biotif-Medium-Italic.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Medium';
  src: url("/assets/fonts/Biotif-Medium.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Regular Italic';
  src: url("/assets/fonts/Biotif-Regular-Italic.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Semi Bold Italic';
  src: url("/assets/fonts/Biotif-Semi-Bold-Italic.otf") format("opentype"); }

@font-face {
  font-family: 'Biotif Semi Bold';
  src: url("/assets/fonts/Biotif-Semi-Bold.otf") format("opentype"); }

:root {
  --ph-height: 180px;
  --ph-min-height: 110px;
  --ph-y-padding: 21px;
  --ph-y-padding-sm: 14px;
  --pt-font-size: 48px;
  --pt-min-font-size: 26px;
  --detail-padding: 14px;
  --ant-tab-height: 61px;
  --global-nav-width: 62px;
  --search-result-tile-width: 360px;
  --search-result-tile-out-width: 52px;
  --ant-tab-top: calc(var(--ph-height) + calc(var(--detail-padding) * 2) + var(--ant-tab-height) + 1px);
  --ant-tab-min-top: calc(var(--ph-min-height) + calc(var(--detail-padding) * 2) + var(--ant-tab-height) + 1px);
  --inAntsTabWidth: calc(100vw - var(--global-nav-width) - var(--search-result-tile-width) - calc(var(--detail-padding) * 2));
  --inTabWidth: calc(var(--inAntsTabWidth) - 33px - 42px);
  --outAntsTabWidth: calc(100vw - var(--global-nav-width) - var(--search-result-tile-out-width) - calc(var(--detail-padding) * 2));
  --outTabWidth: calc(var(--outAntsTabWidth) - 33px - 42px);
  --individaulAntsTabWidth: calc(100vw - var(--global-nav-width) - calc(var(--detail-padding) * 2));
  --individaulTabWidth: calc(var(--individaulAntsTabWidth) - 33px - 42px);
  --individaulActivityWidth: calc(100vw - var(--global-nav-width) - calc(var(--detail-padding) * 2) - 33px - 42px);
  --outActivityWidth: calc(100vw - var(--global-nav-width) - var(--search-result-tile-out-width) - calc(var(--detail-padding) * 2) - 33px - 42px);
  --inActivityWidth: calc(100vw - var(--global-nav-width) - var(--search-result-tile-width) - calc(var(--detail-padding) * 2) - 33px - 42px);
  --tab-content-fix-height: calc(100vh - var(--ant-tab-top) - 28px - 28px - var(--detail-padding));
  --tab-content-fix-height-sm: calc(100vh - var(--ant-tab-min-top) - 28px - 28px - var(--detail-padding));
  --kanban-content-min-height: calc(100vh - var(--ant-tab-top) - 28px - 28px - var(--detail-padding) - 119px);
  --kanban-content-min-height-sm: calc(100vh - var(--ant-tab-min-top) - 28px - 28px - var(--detail-padding) - 119px);
  --form-height: calc(100vh - calc(var(--detail-padding) * 2) - 130px - 76px);
  --form-height-sm: calc(100vh - calc(var(--detail-padding) * 2) - 95px - 76px);
  --theme-background: #272668;
  --theme-button-color: #2dcaff;
  --theme-footer-background: #151529;
  --theme-image: url('/assets/img/empty-16x16.png'); }

/* font-family */
/* font size */
/* font-size better use the rem */
/* animation duration */
/* color */
/* (Details and Form Backgrounds ) */
/* (The background of the page itself) */
/* object signature colors */
/* tab circle color based on entity type */
/* Form */
/* Details */
/* activity timeline */
/* clickable area on the top of a dark purple background */
#AtsContentsList {
  background-color: #fff;
  padding: 5em;
  margin: 5em; }
  #AtsContentsList #list-wrapper {
    min-width: 80rem;
    overflow-x: scroll !important; }
  #AtsContentsList .image {
    -ms-flex: 0 0 5rem;
    flex: 0 0 5rem; }
  #AtsContentsList img {
    width: auto;
    height: 26px;
    margin-left: 1rem; }
  #AtsContentsList .entityTypes,
  #AtsContentsList .name {
    -ms-flex: 0 0 16rem;
    flex: 0 0 16rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #AtsContentsList .isRegistered,
  #AtsContentsList .firstName,
  #AtsContentsList .lastName,
  #AtsContentsList .priority,
  #AtsContentsList .dueDate,
  #AtsContentsList .userId {
    -ms-flex: 0 0 8rem;
    flex: 0 0 8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #AtsContentsList .assignedToUserName {
    -ms-flex: 0 0 10rem;
    flex: 0 0 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #AtsContentsList .subject {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #AtsContentsList .table-content-col-item.relatedEntityName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #4948ac;
    font-family: "Biotif Semi Bold", "Biotif", sans-serif; }
    #AtsContentsList .table-content-col-item.relatedEntityName:hover {
      color: #007bff;
      text-decoration: none;
      background-color: transparent; }
  #AtsContentsList .role {
    -ms-flex: 0 0 12rem;
    flex: 0 0 12rem;
    overflow: hidden;
    text-overflow: ellipsis; }
  #AtsContentsList .status,
  #AtsContentsList .numUsers,
  #AtsContentsList .numDivisions {
    -ms-flex: 0 0 7rem;
    flex: 0 0 7rem; }
  #AtsContentsList .businessUnit {
    -ms-flex: 0 0 12rem;
    flex: 0 0 12rem;
    overflow: hidden;
    text-overflow: ellipsis; }
  #AtsContentsList .desc {
    min-width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
