@import '../../../styles/variables.scss';

#drop-resume-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $base-offwhite;
  transition: 0.5s ease;
  z-index: 9999 !important;
  cursor: pointer;
  margin-left: 0 !important;

  .drop-text {
    width: 30rem;
    height: 20rem;
    position: absolute;
    top: 30%;
    left: 30%;
    background-color: $base-white;
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.col-2.p-1.name {
  flex: 0 0 18%;
  max-width: 18%;
}

.col-2.p-1.visibiltyWidth {
  flex: 0 0 13.666667%;
  max-width: 13.666667%;
}

.col-2.p-1.attachmentType {
  flex: 0 0 14.666667%;
  max-width: 14.666667%;
}

.col-2.p-1.dateType {
  flex: 0 0 18%;
  max-width: 18%;
}

#resume-parsing {
  padding: 0;
}

.dropzone {
  width: 100%;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 0.25em;
  border-color: $drop-zone-border;
  border-style: dashed;
  background-color: $drop-zone-bg;
  transition: border 0.24s ease-in-out;
  clear: both;

  &.active {
    border-width: 2px;
    border-color: $vivid-blue;
  }

  &.error {
    border-color: $field-border-error;
  }

  &:focus {
    border-color: $field-border-selected;
  }

  .bold-text,
  .file-extensions,
  .or {
    font-size: $font-size-16;
  }

  .bold-text {
    font-family: $biotif-semi-bold;
    color: $black;
  }

  .file-extensions {
    color: $dark-grey !important;
    font-family: $biotif;
  }

  .or {
    color: $light-grey !important;
    font-family: $biotif;
  }
}

.file-name {
  font-size: $font-size-16;
  font-family: $biotif-semi-bold;
  color: $black;
}

.file-row {
  margin: 2rem 0 !important;
  padding: 1rem;
  border: 1px solid $field-border;
  border-radius: 0.25rem;
  align-items: center;
}

#close-drop-zone,
#done-drop-zone {
  width: 10rem;
  height: 3.5rem;
  text-align: center;
}

#files-list-header {
  min-height: 25px !important;
  text-align: justify;
  color: #9399b2;
  font-size: 11px;
  margin-left: 0.2rem;
}

#files-list-body {
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: #f3f4f8;

  .row {
    position: relative;

    .files-list-col {
      position: relative;

      &.files-list-remove {
        position: absolute;
        right: -0.5rem;
        font-size: 22px;
        top: 0.8rem;
        cursor: pointer;
      }

      .ats-date-picker .react-datepicker-wrapper .react-datepicker__input-container {
        bottom: 0 !important;
      }

      .ats-date-picker label.calendar-icon.form::after {
        top: 15px !important;
      }
    }
  }
}
