@import '../../styles/variables.scss';

#sidebar {
  overflow-y: auto !important;
  height: 100vh !important;
  position: fixed;
  top: 0;
  background-color: $side-nav-bg;
  z-index: 10;

  .navbar {
    padding: 0.5rem;

    .navbar-nav {
      .nav-item {
        margin-top: 12px;
      }
    }

    &:not(.other-navs) {
      .nav-item:nth-child(2) a {
        margin-top: 78px;
      }
    }

    .active {
      color: $side-nav-active;
    }

    .inactive {
      color: $side-nav-inactive;
    }
  }

  .other-navs {
    margin-left: 6px;
    position: absolute;
    bottom: 0;

    .green-dot {
      position: absolute;
      top: 12%;
      right: 16%;
      height: 15px;
      min-width: 15px;
      background-color: springgreen;
      border-radius: 50%;
      font-size: 12px;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 3px 0 3px;
    }
  }

  @media (max-height: 800px) {
    .other-navs {
      top: 480px;
    }
  }
}
