@import '../../styles/variables.scss';

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black;
  opacity: 0.5;
  transition: 0.5s ease;
  z-index: 100;
  cursor: pointer;
  margin-left: 88px;
}

#side-drawer {
  width: 380px;
  position: fixed;
  left: var(--global-nav-width);
  z-index: 100;
  height: 100vh;
  background-color: white;
  font-family: $biotif-semi-bold;
  font-size: $font-size-16;

  img {
    color: $side-nav-inactive;
  }

  .title {
    font-size: $font-size-22;
    padding-left: 30px;
  }

  .divider {
    background-color: $lighter-grey;
    height: 3px;
    margin: 0.5rem 2rem 0.5rem 3rem;

    &.black-divider {
      background-color: $black;
    }
  }

  .divider-100 {
    height: 1px;
    margin: 1rem 0 0.5rem 0;
  }

  .drawer-content {
    margin-top: 80px;//108px;
  }

  .searchLabel {
    top: 20px;
    left: 140px;
  }

  input#search-input {
    background-color: $base-white;
    border: 0;
    box-shadow: none !important;
    outline: 0;

    &:focus,
    &:hover {
      border: 0;
      box-shadow: none !important;
    }
  }

  ::placeholder,
  #placeholder {
    font-size: $font-size-22;
    color: $black !important;
    font-family: $biotif-semi-bold;
  }

  #quick-search-drawer,
  #notification-drawer {
    .filter {
      margin: 1rem 2rem 0 3rem;
    }

    #quick-search-items,
    #notification-items {
      position: fixed;
      top: 16.5rem;
      bottom: 0;
      overflow-y: auto !important;
      width: 358px;
      padding-right: 10px;
      -webkit-animation: fadeinout $animation-duration ease-in-out forwards;
      animation: fadeinout $animation-duration ease-in-out forwards;

      div.circle {
        margin-top: 10px;
      }
    }

    .search-bar-container {
      display: inline;

      .search-bar-input {
        min-width: 18.5rem;
      }

      input#search-input {
        height: 33px;
        border-bottom: 1px solid #c7c7c9fa !important;
        box-sizing: border-box;
      }
    }
  }

  #recent-activity-drawer {
    #new-search-btn {
      width: 250px;
      margin-left: 3rem;
    }

    #recent-activity-header {
      padding: 0 1.5rem;
      margin: 0;
      font-size: $font-size-14;
      list-style: none;
      display: flex;
      text-align: center;
      color: #4948ac;

      li {
        padding: 0.5rem;
        height: 35px;
        text-align: center;
        background-color: $base-offwhite;

        span {
          width: 141px;
        }
      }

      li:first-child {
        border-radius: 2rem 0 0 2rem;
      }

      li:last-child {
        border-radius: 0 2rem 2rem 0;
      }

      li.active-recent-tab {
        background-color: $light-grey;
        opacity: 0.75;
        color: $base-white;
      }
    }

    #recent-activity-items {
      position: fixed;
      top: 21.5rem;
      bottom: 0;
      overflow-y: auto !important;
      width: 317px;
      margin: 0.2rem 0 0.5rem 3rem;
      -webkit-animation: fadeinout $animation-duration ease-in-out forwards;
      animation: fadeinout $animation-duration ease-in-out forwards;

      .item {
        padding-top: 1.5rem;
        line-height: 26px;
        padding-right: 10px;
      }
    }
  }
}
