@import '../../../styles/variables.scss';

#submission-form-header {
  #submission-form-title {
    .name {
      margin-right: 6rem;
      min-width: 30rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .radio-container {
      min-width: 12rem;
      font-family: $biotif-semi-bold;
      font-size: 18px !important;
    }
  }
}

#submission-form-modal {
  min-width: 75rem;

  .center-section {
    display: flex;
    margin: auto;

    .center-section-object {
      margin: auto; /* Important */
      padding-top: 2.2rem;
      text-align: center;
    }
  }

  .rbt-input {
    height: auto !important;
    min-height: calc(2em + 0.85rem + 3.5px) !important;

    .rbt-input-wrapper {
      margin-top: 1px !important;
    }
  }

  .checkbox-container {
    font-family: "Biotif", sans-serif;
    padding-left: 30px !important;
    margin-left: 0.5rem !important;
    margin-top: 22px;
    font-size: 16px;
  }
}

.toast-response-background {
  background: #2dcaff !important;
  border-radius: 8px !important;
  text-align: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3) !important;
}

.toast-response-body {
  font-size: 16px;
  font-family: Biotif-SemiBold, Biotif, serif;
  color: #fff;
  margin: auto 0;
  flex: 1 1;
}
