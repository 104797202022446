@import '../../../styles/variables.scss';

.ats-header-inline,
.ats-inline {
  .editIcon {
    display: inline-block;
    content: '';
    background-repeat: no-repeat;
    background-size: 23px 22px;
    width: 23px;
    height: 22px;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: -6px;
  }
}

.ats-header-inline {
  .editIcon {
    margin-bottom: -4px;
  }
}

.ats-header-inline:hover {
  .editIcon {
    background-image: url('/assets/img/inlineEdit/edit.png');
  }
}

.ats-inline {
  &:hover {
    background-color: $base-offwhite;

    .editIcon {
      margin-bottom: -6px;
      background-image: url('/assets/img/inlineEdit/edit-hover.png');
    }
  }

  &:not(.displayValueInline):hover {
    margin-left: -1rem !important;
    padding-left: 1rem !important;
  }

  &.displayValueInline {
    margin-bottom: 1rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 1rem !important;
  }
}

#contact-phone {
  .ats-inline {
    &:hover {
      div:not(:first-child) {
        .editIcon {
          display: none;
        }
      }
    }
  }
}

.ats-inline-edit {
  background-color: $base-offwhite !important;
  margin-left: -1rem !important;
  padding: 1rem 1rem 0 1rem !important;
  position: relative;

  .form-control {
    background-color: transparent;
  }

  #task-creation {
    position: absolute;
    bottom: 0.4rem;
    width: auto;
    background-color: $base-offwhite !important;

    .checkbox-container {
      margin-left: 0 !important;
    }
  }

  #placement-task-creation {
    width: auto;
    background-color: $base-offwhite !important;
    text-align: left !important;

    .checkbox-container {
      margin-left: 0 !important;
      color: #4d536d;
      font-size: 16px;
    }
  }
}

#title-company {
  .label {
    display: none !important;
  }

  span.editIcon {
    display: none;
  }

  .prev-cmp,
  .prev-title {
    span.editIcon {
      display: inline-flex;
    }
  }

  .ats-inline-edit .label {
    display: block !important;
  }
}

#details-tab .ant-tabs.ant-tabs-card .ant-tabs-tabpane .ats-inline-edit .label {
  font-family: $biotif !important;
}

.main-program {
  background-color: #b7b7fc;
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-top: 10px;
  height: 75px;
  max-width: 400px;

  .d-none {
    display: none !important;
  }
}
