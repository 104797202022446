@import '../../../styles/variables.scss';

#detail-header {
  padding-bottom: 2rem;

  #detail-title {
    margin-right: 3rem;

    .name {
      max-width: 40rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      width: auto;
      height: 45px;
      margin-left: 0.5rem;
      margin-bottom: 0.7rem;
    }
  }
}

#detail-modal {
  min-width: 60rem;

  .detail-item {
    padding-bottom: 2rem;

    .item-name {
      font-family: $biotif-semi-bold;
      color: #17191c;
    }

    .item-user {
      padding-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 20rem;
    }

    #summary {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
