#holiday-image {
  background-image: var(--theme-image);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 160px;
  margin-right: 12px;
  position: fixed;
  top: 14%;
  left: calc((100vw - 160px) / 2);
  //animation: holiday-animation ease-in-out 10s infinite alternate;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  white-space: nowrap;
  font-family: "Biotif Semi Bold", "Biotif", sans-serif;
}

@keyframes holiday-animation {
  0% { transform: rotate(0deg); }
  100% { transform: rotateZ(360deg); }
}

.rainbow-text {
  background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow 2s ease infinite;
  margin-left: -18px;
}

@keyframes rainbow {
  0% { background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red); }
  15% { background-image: linear-gradient(to left, indigo, blue, green, yellow, orange, red, violet); }
  30% { background-image: linear-gradient(to left, blue, green, yellow, orange, red, violet, indigo); }
  45% { background-image: linear-gradient(to left, green, yellow, orange, red, violet, indigo, blue); }
  60% { background-image: linear-gradient(to left, yellow, orange, red, violet, indigo, blue, green); }
  75% { background-image: linear-gradient(to left, orange, red, violet, indigo, blue, green, yellow); }
  90% { background-image: linear-gradient(to left, red, violet, indigo, blue, green, yellow, orange); }
  100% { background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red); }
}
