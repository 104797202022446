#bulk-email-form-modal {
  .form-inline .form-control {
    width: 100% !important;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .rbt-input-multi .rbt-input-wrapper {
    input.rbt-input-main {
      padding-top: 5px !important;
    }
  }
}
