@import '../../../styles/variables.scss';

.btn {
  color: $base-white !important;
  font-family: $biotif-bold;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  padding: 1rem !important;
  border: none !important;
  box-shadow: none !important;

  @media (max-width: 1500px) {
    .button_text {
      display: none;
    }
  }

  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    background-color: $button-bg-hover !important;
  }

  &.atsDropdownButton.btn-light {
    text-transform: none !important;
    padding: 0 0.938rem !important;
    background-color: $base-white;
  }

  &.active {
    background-color: $vivid-blue !important;
  }

  &.selected,
  &.not-selected {
    padding: 0.625rem 0.938rem !important;
    text-transform: none;
    font-size: $font-size-12;
  }

  &.selected {
    background-color: $lighter-grey !important;
    color: $dark-grey !important;
  }

  &.not-selected {
    background-color: #f4f4ff !important;
    color: $button-bg !important;
  }
}

.btn-inline-actions {
  .btn {
    &#cancel-inline,
    &#save-inline {
      margin: 1rem 0 0.5rem 0;
    }

    &#cancel-section,
    &#save-section {
      margin-bottom: 0.8rem;
    }

    padding: 0 !important;
    height: 2.5rem;

    &#cancel-inline::after,
    &#save-inline::after,
    &#cancel-section::after,
    &#save-section::after {
      display: inline-block;
      content: '';
      background-repeat: no-repeat;
      background-size: 5rem 2.938rem;
      width: 5rem;
      height: 2.938rem;
    }

    &#cancel-inline::after,
    &#cancel-section::after {
      background-image: url('/assets/img/inlineEdit/cancel.png');
    }

    &#save-inline::after,
    &#save-section::after {
      background-image: url('/assets/img/inlineEdit/save.png');
    }

    &#cancel-inline:hover::after,
    &#cancel-section:hover::after {
      background-color: $base-white !important;
      background-image: url('/assets/img/inlineEdit/cancel-hover.png');
    }

    &#save-inline:hover::after,
    &#save-section:hover::after {
      background-image: url('/assets/img/inlineEdit/save-hover.png');
    }
  }
}

.btn-primary,
.btn-no-form {
  background-color: $button-bg !important;
}

.btn-primary,
.ats-lg-btn {
  font-size: $font-size-16 !important;
}

.btn.btn-primary,
.btn.selected {
  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    background-color: $disabled-button-bg !important;
  }
}

.ats-sm-btn,
.ats-md-btn {
  font-size: $font-size-13 !important;
  font-family: $biotif-semi-bold;
  padding: 0.5rem 1.3rem !important;
  letter-spacing: 0.156rem;
}

.ats-md-btn {
  height: 3.75rem;
  width: 17.5rem;
}

.ats-sm-btn {
  height: 2.5rem;
}

.ats-lg-btn {
  height: 5rem;
  width: 25rem;
}

#replaceResume {
  padding: 0.625rem 1.563rem !important;
  font-family: $biotif-semi-bold;
  font-size: $font-size-13 !important;
  border: 0.063rem solid $button-bg !important;
  color: $button-bg !important;
}

.btn-secondary {
  padding: 1rem 1.5rem !important;
  border: 0.063rem solid $field-border !important;
  color: $light-grey !important;
}

#replaceResume,
.btn-secondary {
  background-color: $base-white !important;
  text-align: center;
  width: 12.5rem;

  &.ats-lg-btn {
    width: 16.563rem;
  }

  &:hover {
    background-color: $base-white !important;
    border: 0.063rem solid $vivid-blue !important;
    color: $vivid-blue !important;
  }

  &.close {
    font-size: $font-size-13;
    font-family: $biotif-semi-bold;
  }
}

.btn-no-form {
  padding: 0.625rem 1.563rem !important;
}

#clear-all-search,
#submit-search,
.btn-no-form {
  font-family: $biotif-semi-bold;
  font-size: $font-size-13 !important;
}

button#cancel-save-search {
  width: 13.438rem;
}

#listAddBtn {
  margin-left: 1rem;
  width: 5.313rem !important;
  height: 2.938rem !important;
}
