@import '../styles/variables.scss';

.form-group {
  &.firstPreferredField {
    max-width: 28% !important;
    flex: 0 0 28%;
  }

  &.middlePronounField {
    max-width: 22% !important;
    flex: 0 0 22%;
  }

  #isPublic {
    width: auto;
    position: absolute;
    top: -0.8rem;
    left: 2rem;
  }
}

.form-control {
  .checkboxClass {
    margin-left: 0 !important;
  }
}

.ats-inline-edit {
  .checkboxClass {
    background-color: #f3f4f8 !important;
  }
}

#content {
  .form-container {
    background-color: $base-white;
    padding: 0 0 50px 50px;

    .form-title {
      background-color: $base-white;

      .form-title-text {
        padding-top: 30px;
        font-size: var(--pt-font-size);

        @media only screen and (max-width: 1280px) {
          font-size: var(--pt-min-font-size);
        }

        font-family: $biotif-semi-bold;
        color: $page-title;
      }

      .form-color-line {
        margin: 25px 0 0 0;
        width: 100px;
        border-top: 3px solid $vivid-blue;
      }
    }

    label {
      color: $dark-grey;
      font-family: $biotif;
      font-size: $font-size-16;
      height: 30px;

      .required {
        color: $field-required-start;
        font-size: $font-size-20;
      }

      .help-text {
        padding-left: 15px;
        color: $light-grey !important;
        font-family: $biotif;
        font-size: $font-size-14;

        &::before {
          content: '(';
        }

        &::after {
          content: ')';
        }
      }
    }

    .form-section-title {
      margin: 55px 0 5px 0;
      font-size: $font-size-34;
      font-family: $biotif-semi-bold;
      color: $page-title;

      label {
        margin-top: 14px;
        font-family: $biotif-semi-bold;
      }
    }

    form[name='candidateForm'],
    form[name='contactForm'],
    form[name='companyForm'],
    form[name='jobForm'],
    form[name='placementForm'],
    form[name='userForm'] {
      padding-right: 50px;
      height: var(--form-height);
      overflow-y: auto;
      padding-bottom: 180px;

      .form-row {
        .form-group:first-child {
          padding-left: 1px !important;
        }
      }

      @media only screen and (max-width: 1280px) {
        height: var(--form-height-sm);
      }

      #task-creation {
        width: calc(20rem + 290px);

        .checkbox-container {
          margin-top: 10px;
        }
      }

      .float-buttons {
        left: 0;
        padding: 0 50px 14px 50px !important;
        height: 74px;
        background-color: white;
        position: absolute;
        bottom: -36px;

        > div {
          padding-top: 14px;
          border-top: 1px solid #e4e4eb;
        }

        .btn-secondary {
          width: 150px;
          font-size: $font-size-14 !important;
          height: 44px;
          padding: 10.5px 1.5rem !important;
        }

        .btn-primary {
          width: 205px;
          font-size: $font-size-14 !important;
          height: 44px;
          padding: 10.5px 0.5rem !important;
          white-space: nowrap;

          &#submit-user-delay-email {
            width: 280px;
          }
        }
      }
    }
  }
}
