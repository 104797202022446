#notification-selection {
  position: absolute;
  top: 1rem;
  right: 2%;

  .notification-selection-title {
    color: #4948ac !important;
  }
}

#notification-drawer {
  .item:hover {
    background-color: #f3f4f8;
  }
}

#notification-list {
  height: calc(100vh - 245px);
  overflow-y: auto !important;
  position: relative;

  .items-group-title {
    padding-left: 4.9rem !important;
    font-size: 14px;
    margin: 1rem 0;
  }

  .unread {
    background-color: #e2f8ff;
    border-right: 2px solid #2dcaff !important;
  }

  .flagged {
    background-color: #fff0f4;
    border-right: 2px solid #f64869 !important;
  }

  .notification-bulk-update {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #c9cbd3;
    font-size: 14px;

    &:hover {
      color: #2dcaff;
    }
  }
}

#notification-item {
  position: relative;

  .notification-target-name {
    overflow: hidden;
    white-space: pre-wrap;
    color: #4948ac;
    font-size: 16px;
    font-family: "Biotif Semi Bold", "Biotif", sans-serif;
  }

  .notification-delete {
    font-size: 18px;
    position: absolute;
    bottom: 12%;
    right: 26%;
    display: none;

    &:hover {
      opacity: 0.5;
    }

    &.target {
      right: 15%;
    }
  }

  .notification-reply-to {
    content: ' ';
    background-image: url('/assets/img/Notify-ReplyTo.png');
    background-repeat: no-repeat;
    background-size: auto 16px;
    width: auto;
    height: 16px;
    padding-left: 20px;
    position: absolute;
    bottom: 12%;
    right: 15%;
    display: none;

    &:hover {
      background-image: url('/assets/img/Notify-ReplyTo-hover.png');
    }
  }

  .notification-has-replied {
    content: ' ';
    background-image: url('/assets/img/Notify-ReplyTo-hover.png');
    background-repeat: no-repeat;
    background-size: auto 16px;
    vertical-align: middle;
    width: auto;
    height: 16px;
    padding-left: 20px;
    position: absolute;
    bottom: 12%;
    right: 15%;

    &:hover {
      opacity: 0.5;
    }
  }

  .notification-flag {
    color: #f64869;
    font-size: 18px;
    position: absolute;
    bottom: 12%;
    right: 5%;

    &:hover {
      opacity: 0.5;
    }
  }

  .notification-unflag {
    font-size: 18px;
    position: absolute;
    bottom: 12%;
    right: 5%;
    display: none;

    &:hover {
      opacity: 0.5;
    }
  }

  &:hover .notification-reply-to,
  &:hover .notification-has-replied,
  &:hover .notification-flag,
  &:hover .notification-unflag,
  &:hover .notification-delete {
    display: inline;
  }
}
