@import '../../../styles/variables.scss';

div[id^="jobFunctionIds-"],
div[id^="skillIds-"] {
  .ats-select__option {
    padding-left: 2rem;
  }
}

.select {
  &.form-control {
    padding: 0 !important;
    border: none;
    height: auto !important;

    .ats-select__control {
      min-height: calc(2em + 0.85rem + 3.5px) !important;
    }

    .ats-select__control--is-focused {
      cursor: pointer;
      box-shadow: none !important;
      background-image: none !important;
    }

    .ats-select__placeholder {
      opacity: 1;
      font-family: $biotif;
    }

    .ats-select__multi-value {
      color: $button-bg-hover;
      border-radius: 1rem;
      padding: 0.2rem 0.3rem;
      font-family: $biotif-semi-bold;
    }

    .ats-select__multi-value__label {
      color: $button-bg-hover;
      font-size: $font-size-16;
      padding-left: 0.5rem !important;
      //font-family: $biotif-semi-bold;
    }

    .multi .ats-select__indicators {
      display: none;
    }

    .ats-select__option {
      &:hover {
        color: $button-bg;
      }
    }

    .ats-select__option--is-focused {
      color: $button-bg;
    }

    .ats-select__option--is-selected {
      color: $base-white;
    }

    .ats-select__menu {
      //scrollbar-width: thin;
      scrollbar-color: #e3e3e3 $base-white;
    }

    #industryIds,
    #source,
    #groupByName {
      .ats-select__menu {
        z-index: 10;
      }
    }

    .error {
      .ats-select__control {
        border-color: $field-border-error !important;
      }
    }

    .ats-select__multi-value__remove {
      &:hover {
        color: $field-border-error;
        background-color: inherit;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

#jobFunctionIds,
#activityTypeIds {
  .ats-select__option {
    padding-left: 2rem;
  }
}
