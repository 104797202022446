@import '../../../styles/variables.scss';

#list .header {
  .new,
  .modify,
  .save {
    color: $timeline-btn-bg !important;
  }
}

#details-title-wrapper .header {
  color: $detail-header-link-color !important;

  .dropdown-menu {
    position: fixed !important;
    z-index: 3;
    padding: 0.5rem;

    .dropdown-item {
      letter-spacing: 1px;
      padding: 0.25rem 0.75rem;

      &:hover,
      &:focus {
        background-color: $detail-header-link-color;
      }
    }
  }

  #moreSearchOpen img.moreOptionShowImg {
    display: none;
  }

  #moreSearchOpen img.moreOptionImg {
    display: block;
  }

  .show {
    #moreSearchOpen img.moreOptionImg {
      display: none;
    }

    #moreSearchOpen img.moreOptionShowImg {
      display: block;
    }
  }
}

#details-title-wrapper .header,
#list .header {
  .new,
  .modify,
  .save {
    margin: 0 1.3rem 0 0;
    font-family: $biotif-semi-bold;
    font-size: 16px;

    &:hover {
      color: $side-nav-active !important;
      text-decoration: none !important;
    }

    &.disabled {
      color: $light-grey !important;
    }
  }

  .atsDropdown {
    #moreSearchOpen.dropdown-toggle::after {
      display: none;
    }
  }
}

#list.out {
  .header {
    .atsDropdown {
      visibility: hidden;
    }
  }
}

#list.in {
  .header {
    .atsDropdown {
      visibility: visible;
    }
  }
}

#save-report-modal,
#save-search-modal {
  input::-moz-selection {
    color: $black !important;
    background: $highlight-text-bg;
  }

  input::selection {
    color: $black !important;
    background: $highlight-text-bg;
  }
}
