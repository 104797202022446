@import '../../../styles/variables.scss';

.ats-rich-text-label {
  position: relative;

  .select-template-icon {
    position: absolute;
    right: 0;
    bottom: 0.7rem;
    vertical-align: middle;
    cursor: pointer;
    color: $timeline-btn-bg;

    &::before {
      display: inline-block;
      content: '';
      background-repeat: no-repeat;
      background-size: 13px 13px;
      width: 13px;
      height: 13px;
      padding-right: 20px;
      background-image: url('/assets/img/bulkActions/add-to-list.png');
    }

    &:hover {
      color: $vivid-blue;

      &::before {
        background-image: url('/assets/img/nav/more-options-active.png');
      }
    }
  }
}
