#edit-modal {
  .label-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .label-text {
    width: 100%;
  }

  #sendInviteToCandidates,
  #sendInviteToContacts {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    .checkbox-container {
      margin: 0;
      padding: 0 2rem 0 0 !important;

      .checkboxmark {
        left: unset;
        right: 0 !important;
      }
    }
  }
}
