.ats-date-picker {
  .react-datepicker__input-container {
    bottom: 7px !important;
  }

  label.calendar-icon.form::after {
    top: 50px !important;
  }
}

#edit-modal {
  .modal-body {
    form[name='appointmentForm'] {
      .ats-date-picker {
        margin-top: 0.5rem !important;
      }
    }
  }
}
