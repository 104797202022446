.rdt_Table {
  width: 100%;
  max-width: 1165px;
}

.rdt_TableHeadRow {
  color: #9399b2;
  font-size: 0.875rem;
  font-weight: 600;
}

.rdt_TableCell {
  color: var(--theme-footer-background);
  font-size: 0.875rem;
}

.expanded__container {
  color: var(--theme-footer-background);
  background-color: whitesmoke;
  font-size: 0.875rem;
  padding: 1rem;
}

.expanded__title {
  color: #9399b2;
  font-weight: 600;
  font-size: 1rem;
}
