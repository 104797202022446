@import './font-library.scss';

:root {
  --ph-height: 180px;
  --ph-min-height: 110px;
  --ph-y-padding: 21px;
  --ph-y-padding-sm: 14px;
  --pt-font-size: 48px;
  --pt-min-font-size: 26px;
  --detail-padding: 14px;
  --ant-tab-height: 61px;
  --global-nav-width: 62px;
  --search-result-tile-width: 360px;
  --search-result-tile-out-width: 52px;
  --ant-tab-top: calc(var(--ph-height) + calc(var(--detail-padding) * 2) + var(--ant-tab-height) + 1px);
  --ant-tab-min-top: calc(var(--ph-min-height) + calc(var(--detail-padding) * 2) + var(--ant-tab-height) + 1px);
  --inAntsTabWidth: calc(100vw - var(--global-nav-width) - var(--search-result-tile-width) - calc(var(--detail-padding) * 2));
  --inTabWidth: calc(var(--inAntsTabWidth) - 33px - 42px);
  --outAntsTabWidth: calc(100vw - var(--global-nav-width) - var(--search-result-tile-out-width) - calc(var(--detail-padding) * 2));
  --outTabWidth: calc(var(--outAntsTabWidth) - 33px - 42px);
  --individaulAntsTabWidth: calc(100vw - var(--global-nav-width) - calc(var(--detail-padding) * 2));
  --individaulTabWidth: calc(var(--individaulAntsTabWidth) - 33px - 42px);
  --individaulActivityWidth: calc(100vw - var(--global-nav-width) - calc(var(--detail-padding) * 2) - 33px - 42px);
  --outActivityWidth: calc(100vw - var(--global-nav-width) - var(--search-result-tile-out-width) - calc(var(--detail-padding) * 2) - 33px - 42px);
  --inActivityWidth: calc(100vw - var(--global-nav-width) - var(--search-result-tile-width) - calc(var(--detail-padding) * 2) - 33px - 42px);
  --tab-content-fix-height: calc(100vh - var(--ant-tab-top) - 28px - 28px - var(--detail-padding));
  --tab-content-fix-height-sm: calc(100vh - var(--ant-tab-min-top) - 28px - 28px - var(--detail-padding));
  --kanban-content-min-height: calc(100vh - var(--ant-tab-top) - 28px - 28px - var(--detail-padding) - 119px);
  --kanban-content-min-height-sm: calc(100vh - var(--ant-tab-min-top) - 28px - 28px - var(--detail-padding) - 119px);
  --form-height: calc(100vh - calc(var(--detail-padding) * 2) - 130px - 76px);
  --form-height-sm: calc(100vh - calc(var(--detail-padding) * 2) - 95px - 76px);
  --theme-background: #272668;
  --theme-button-color: #2dcaff;
  --theme-footer-background: #151529;
  --theme-image: url('/assets/img/empty-16x16.png');
}

/* font-family */
$biotif: 'Biotif';
$biotif-black-italic: 'Biotif Black Italic';
$biotif-black: 'Biotif Black';
$biotif-bold-italic: 'Biotif Bold Italic';
$biotif-bold: 'Biotif Bold';
$biotif-book-italic: 'Biotif Book Italic';
$biotif-book: 'Biotif Book';
$biotif-extraBold-italic: 'Biotif ExtraBold Italic';
$biotif-extraBold: 'Biotif ExtraBold';
$biotif-light-italic: 'Biotif Light Italic';
$biotif-light: 'Biotif Light';
$biotif-medium-italic: 'Biotif Medium Italic';
$biotif-medium: 'Biotif Medium';
$biotif-regular-italic: 'Biotif Regular Italic';
$biotif-semi-bold-italic: 'Biotif Semi Bold Italic';
$biotif-semi-bold: 'Biotif Semi Bold';

/* font size */

/* font-size better use the rem */
$font-size-48: 48px;
$font-size-36: 36px;
$font-size-34: 34px;
$font-size-33: 33px;
$font-size-32: 32px;
$font-size-30: 30px;
$font-size-28: 28px;
$font-size-24: 24px;
$font-size-22: 22px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-17: 17px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-13: 13px;
$font-size-12: 12px;

/* animation duration */
$animation-duration-short: 0.25s;
$animation-duration: 0.5s;
$animation-duration-long: 1s;

/* color */
$vivid-blue: #2dcaff;
$secondary: #00ff90;

$base-white: #fff; /* (Details and Form Backgrounds ) */
$base-offwhite: #f3f4f8; /* (The background of the page itself) */
$side-nav-bg: #1a1a32;
$side-nav-active: $vivid-blue;
$side-nav-inactive: #656584;
$black: #17191c;
$dark-grey: #4d536d;
$light-grey: #9399b2;
$lighter-grey: #e4e4eb;
$highlight-text-bg: rgba(45, 202, 255, 0.25);

/* object signature colors */
$candidates: #00ff90;
$jobs: #f64869;
$contacts: #ff6500;
$companies: $vivid-blue;
$notes: #00a8c4;
$emails: #6e1dce;
$placements: #b921ff;
$submissions: #f2bc12;
$appointments: #f2bc12;
$tasks: #b9bccc;

/* tab circle color based on entity type */
$tab-circle-placements: #b7b7fc;
$tab-circle-jobs: #ff6895;
$tab-circle-contacts: #fd7f2b;

$page-title: #1a1a32;

/* Form */
$field-border: #c9cbd3;
$field-border-selected: $vivid-blue;
$field-required-start: $vivid-blue;
$field-border-error: #cd1d58;
$field-border-warn: orange;

$drop-zone-bg: #f5faff;
$drop-zone-border: #95abd2;

$skill-tag-bg: #e2f8ff;
$skill-tag: $vivid-blue;

$button-bg: #4948ac;
$button-bg-hover: #2dcaff;
$disabled-button-bg: #c9cbd3;

/* Details */
$detail-header-bg: #222247;
$detail-header-link-color: #b7b7fc;
$detail-label: #17191c;
$tab-border: #e4e4eb;

/* activity timeline */
$timeline-btn-bg: #4948ac;
$timeline-btn-plus-text: #b7b7fc;
$timeline-border: #c9cbd3;

$light-purple: #9291ff; /* clickable area on the top of a dark purple background */
