@import '../../../styles/variables.scss';

.select-personalize-icon {
  position: absolute;
  right: 8px;
  bottom: 0.7rem;
  vertical-align: middle;
  cursor: pointer;
  color: $timeline-btn-bg;

  &::after {
    display: none !important;
  }

  &::before {
    display: inline-block;
    content: '';
    background-repeat: no-repeat;
    background-size: 13px 13px;
    width: 13px;
    height: 13px;
    padding-right: 20px;
    background-image: url('/assets/img/bulkActions/submit-candidates.png');
  }

  &:hover {
    color: $vivid-blue;

    &::before {
      background-image: url('/assets/img/nav/candidates-active.png');
    }
  }
}

.ats-rich-text-label {
  .select-personalize-icon {
    right: 120px;
  }
}
