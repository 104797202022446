@import '../../../styles/variables.scss';

.fade {
  transition: opacity 0.5s linear !important;
}

.modal-header {
  button {
    opacity: 1;
    font-weight: normal;
    text-shadow: none;
    background: url('/assets/img/modal-close.png') no-repeat;
    background-size: 22px 22px;
    width: 22px;
    height: 22px;

    &:hover {
      opacity: 1;
      background: url('/assets/img/modal-close-hover.png') no-repeat;
      background-size: 22px 22px;
      width: 22px;
      height: 22px;
    }

    &:focus,
    &:active {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }

    span {
      display: none;
    }

    &.close {
      //margin-top: -2.5rem;
      padding: 1rem 1rem;
      margin: 1rem -0.5rem 0 auto;
    }
  }
}

.modal {
  font-size: $font-size-16;
  color: $dark-grey;
  font-family: $biotif;

  .modal-content {
    padding: 3.5rem 2rem 1.75rem 2rem;

    .modal-header {
      border-bottom: 0 !important;
      padding-bottom: 0;

      .modal-title {
        font-size: $font-size-36;
        font-family: $biotif-semi-bold;
        color: $page-title;
      }

      span {
        margin-right: 40px;
        cursor: pointer;
        font-size: $font-size-14 !important;
        font-family: $biotif;
        color: $base-white;
      }
    }

    .rbt-input-hint {
      display: none !important;
    }

    .modal-footer {
      display: inline-block;
    }
  }

  #detail-modal {
    #detail-header {
      .delete::before {
        margin-left: 1rem;
        display: inline-block;
        content: '';
        background-repeat: no-repeat;
        background-size: 44px 44px;
        width: 44px;
        height: 44px;
        background-image: url('/assets/img/Delete Icon - Header.png');
      }

      .edit-link::before {
        margin-left: 1rem;
        display: inline-block;
        content: '';
        background-repeat: no-repeat;
        background-size: 44px 44px;
        width: 44px;
        height: 44px;
        background-image: url('/assets/img/Full Edit Icon Light@2x.png');
      }
    }
  }

  #confirm-modal,
  #confirm-inlineEdit-modal {
    .modal-header {
      display: none !important;
    }

    .modal-content {
      padding: 2rem 2rem 1.75rem 2rem;
    }
  }

  #note-detail-modal,
  #email-detail-modal {
    .close {
      margin-top: 0.5rem;
    }
  }

  #note-detail-modal,
  #email-detail-modal,
  #update-detail-modal {
    .modal-title {
      margin-right: -20px;
      width: 100%;
    }

    .modal-header {
      padding-top: 0 !important;
      margin-top: -0.8rem;
    }
  }

  #update-detail-modal {
    .modal-body {
      margin-top: -21px !important;
    }
  }

  #search-modal {
    .form-control {
      font-family: $biotif;
      font-size: $font-size-14 !important;
      min-height: calc(2em + 0.85rem + 3.5px) !important;
      color: $dark-grey !important;
    }

    .modal-content {
      .card {
        border: 0;
        overflow: visible;

        .card-header {
          background-color: $base-offwhite !important;
          font-size: $font-size-20;
          color: $page-title;
          font-family: $biotif-semi-bold;
        }

        .card-body {
          background-color: $base-offwhite !important;

          .addCriteria {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
        }
      }
    }

    .ats-select__single-value {
      color: $dark-grey;
    }

    .ats-select__multi-value__label {
      font-size: $font-size-14;
    }

    .keyword-location {
      .fa-search {
        padding-left: 0.5rem;
      }

      .form-control {
        height: 60px !important;

        &#keywords {
          padding-left: 2rem;
        }
      }

      .by-location input,
      input[name='keywords'] {
        input {
          font-size: $font-size-16 !important;
          padding-left: 25px;
        }

        a.dropdown-item {
          color: $page-title !important;
        }
      }

      .rbt::after {
        content: url('/assets/img/location.png');
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .rbt-input:not(:focus) {
        border-left: 0 !important;
      }
    }
  }

  #header-modal {
    .pb-3 {
      padding-bottom: 0 !important;
    }
  }

  a {
    &.dropdown-item.disabled {
      color: $light-grey !important;
    }
  }

  .form-container {
    .was-validated {
      .rt-editor.error {
        border-color: $field-border-error;
      }
    }
  }
}

.modal-dialog {
  max-width: 600px !important;
}

@media (min-width: 850px) {
  #bulk-email-form-modal.modal-dialog,
  #note-detail-modal.modal-dialog {
    max-width: 850px !important;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1100px !important;
  }
}
