@import '../../styles/variables.scss';

#select-list-modal {
  .ats-sm-btn {
    &#list-add-btn {
      margin-left: 1rem;
      padding: 0.7rem 1.25rem !important;
      vertical-align: middle;
    }
  }

  #list-add-btn::before {
    content: ' ';
    background-image: url('/assets/img/activities/plus-indigo.png');
    background-repeat: no-repeat;
    background-size: 13px 13px;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    padding-left: 20px;
  }

  #list-add-btn:hover::before {
    background-image: url('/assets/img/activities/plus-blue.png');
  }

  .rbt::after {
    content: '';
    color: #b0b0b3;
    background-image: url('/assets/img/nav/search.png');
    background-size: 13px 13px;
    width: 13px;
    height: 13px;
    position: absolute;
    top: 16px;
    right: 5px;
  }

  .rbt-input-multi.rbt-input-multi {
    padding: 0.51rem 2.2rem 0.61rem 0.8rem !important;
  }
}

#detail-modal {
  .entity-item-list {
    overflow-y: auto;
    max-height: calc(100vh - 350px);

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      -ms-flex: 0 0 16rem;
      flex: 0 0 16rem;
    }
  }

  #new-bulk-email,
  #view-list-search {
    padding: 0.5rem 0.8rem !important;
    vertical-align: middle;
    min-width: 90px;

    &::before {
      content: ' ';
      background-repeat: no-repeat;
      vertical-align: middle;
      padding-left: 29px;
    }
  }

  #new-bulk-email {
    &::before {
      background-size: 18px 15px;
      background-image: url('/assets/img/email-light-purple.png');
      height: 15px;
    }
  }

  #view-list-search {
    &::before {
      background-size: 16px 16px;
      height: 16px;
      background-image: url('/assets/img/search-light-purple.png');
    }
  }
}

#AtsContentsList {
  .nav {
    position: absolute;
    top: 270px;
  }
}
