@import './styles/variables.scss';

html {
  position: relative;
  min-height: 100%;
  width: 100%;
  background-color: $base-offwhite;
  font-weight: normal;
  font-style: normal;
  font-size: $font-size-16;
  font-family: $biotif !important;
  color: $dark-grey !important;
  font-variant: normal !important;
  font-feature-settings: normal !important;

  // firefox scrollbar custom
  //scrollbar-width: thin;  // Firefox
  scrollbar-color: #e3e3e3 $base-white;
}

body {
  background-color: $base-offwhite;
  font-size: $font-size-16;
  font-family: $biotif !important;
  font-variant: normal !important;
  font-feature-settings: normal !important;
  overflow-x: hidden;

  // firefox scrollbar custom
  #side-drawer #recent-activity-drawer #recent-activity-items,
  #list #list-items,
  #location,
  .ats-select__menu .ats-select__menu-list {
    //scrollbar-width: thin;
    scrollbar-color: #e3e3e3 $base-white;
  }

  .logoText {
    font-size: 36px;
    font-family: $biotif-semi-bold;
    color: #4948ac !important;
    margin-left: 14px;
    padding-top: 3px;
  }

  #dashboard {
    .logoText {
      margin-left: 0;
      padding-top: 0;
    }
  }
}

a {
  color: $timeline-btn-bg !important;
  font-family: $biotif-semi-bold;

  &:hover {
    color: $side-nav-active !important;
    text-decoration: none !important;
  }
}

.cursor {
  cursor: pointer;
}

::placeholder,
#placeholder {
  opacity: 1;
  color: $light-grey !important;
  font-family: $biotif;
}

#task-creation {
  .checkbox-container {
    font-family: "Biotif", sans-serif;
    padding-left: 30px !important;
    margin-left: 0.5rem !important;
    margin-top: 22px;
    font-size: 16px;
  }
}

div#ats {
  background-color: $base-offwhite;
  min-height: 100%;
}

#content {
  margin-left: var(--global-nav-width);
  min-height: 100vh !important;
  width: calc(100vw - var(--global-nav-width));
  overflow-x: hidden;
}

.content-statics {
  padding-bottom: 1rem;
  //padding-left: 0.2rem;
  font-size: 16px;
}

textarea.form-control {
  height: 150px !important;
}

.badge {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: $light-grey;
  padding: 6px 0 8px 0;
  width: 30px;
  height: 30px;
  text-align: center;
  background-color: #e3e3e3;
  vertical-align: center;

  .fa-minus::before {
    color: $light-grey;
  }
}

.fa {
  color: $light-grey;
}

.font-size-48 {
  font-size: $font-size-48;
}

.font-size-36 {
  font-size: $font-size-36;
}

.font-size-28 {
  font-size: $font-size-28;
}

.font-size-24 {
  font-size: $font-size-24;
}

.font-size-22 {
  font-size: $font-size-22;
}

.font-size-20 {
  font-size: $font-size-20;
}

.font-size-18 {
  font-size: $font-size-18;
}

.font-size-17 {
  font-size: $font-size-17;
}

.font-size-16 {
  font-size: $font-size-16;
}

.font-size-14 {
  font-size: $font-size-14;
}

.font-size-13 {
  font-size: $font-size-13;
}

.font-size-12 {
  font-size: $font-size-12;
}

.biotif {
  font-family: $biotif;
}

.biotif-book {
  font-family: $biotif-book;
}

.biotif-semi-bold {
  font-family: $biotif-semi-bold;
}

.biotif-bold {
  font-family: $biotif-bold;
}

.section-title {
  font-size: $font-size-34;
  font-family: $biotif-semi-bold;
  color: $page-title;
}

.custom-control-label {
  color: $dark-grey !important;

  &::before {
    border-color: $dark-grey !important;
  }
}

.custom-control-input:checked ~ {
  .custom-control-label {
    &::before {
      box-shadow: none;
      background-color: $button-bg !important;
    }

    &::after {
      background-image: none;
      box-shadow: none;
    }
  }
}

.custom-radio {
  .custom-control-input:checked ~ {
    .custom-control-label {
      &::before {
        box-shadow: none;
        background-color: $button-bg !important;
      }

      &::after {
        background-image: none;
        box-shadow: none;
      }
    }
  }
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* css for all form */
.form-control {
  font-family: $biotif-semi-bold;
  font-size: $font-size-16 !important;
  color: $black !important;
  border-color: $field-border !important;
  height: calc(2em + 0.85rem + 3.5px) !important;

  &:focus,
  &:valid {
    box-shadow: none !important;
  }

  &:focus {
    border-color: $field-border-selected !important;
    cursor: pointer;
  }

  &#email {
    //&:invalid,
    &.is-invalid {
      border: 1px solid $field-border-error !important;
    }
  }

  &.invalid-email {
    border-color: #f2bc12 !important;

    ~ .help-text {
      margin-top: 5px;
      color: #f2bc12 !important;
    }
  }

  &.invalid-email-orig {
    ~ .help-text {
      display: none;
    }
  }
}

.required {
  color: $field-required-start;
  font-size: $font-size-20;
}

.not-required {
  margin-top: 6px;
}

form.was-validated {
  .form-control,
  .ant-input-number-input {
    box-shadow: none !important;
    background-image: none !important;

    &:valid,
    &.is-valid {
      border: 1px solid $field-border;
      background-color: white;
    }

    &:invalid,
    &.is-invalid {
      border: 1px solid $field-border-error !important;
    }
  }

  .invalid-feedback {
    font-size: 100%;
  }

  .required {
    color: $field-required-start;
    font-size: $font-size-20;
  }
}

.progress-bar {
  background-color: $vivid-blue !important;
}

div.circle {
  -moz-border-radius: 50px/50px;
  -webkit-border-radius: 50px 50px;
  border-radius: 50px/50px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin-right: 15px;
  border-width: 3px;
  border-style: solid;

  &.candidates {
    border-color: $candidates;
  }

  &.jobs {
    border-color: $jobs;
  }

  &.contacts {
    border-color: $contacts;
  }

  &.more-options {
    border-color: $contacts;
  }

  &.companies {
    border-color: $companies;
  }

  &.placements {
    border-color: $placements;
  }

  &.targets,
  &.notes {
    border-color: $notes;
  }

  &.emails {
    border-color: $emails;
  }

  &.submissions {
    border-color: $submissions;
  }
}

.typeahead {
  div.circle {
    margin-top: 6px;
  }
}

.rbt-input-hint {
  display: none !important;
}

.rbt-highlight-text {
  background: $highlight-text-bg;
}

.infinite-scroll-component {
  overflow: hidden !important;
  padding-right: 1rem;
}

.off-white-text {
  color: $base-offwhite !important;
}

.dark-blue-text {
  color: $button-bg !important;
}

.dark-grey-text {
  color: $dark-grey;
}

.light-grey-text {
  color: $light-grey;
}

.lighter-grey-text {
  color: $lighter-grey;
}

.red-text {
  color: $field-border-error !important;
}

.orange-text {
  color: $field-border-warn !important;
}

.blue-text {
  color: $vivid-blue;
}

.black-text {
  color: $black;
}

.header-link-text {
  color: $detail-header-link-color !important;
}

.tooltip-inner {
  max-width: 250px;
}

.base-offwhite-bg {
  background-color: $base-offwhite !important;
}

.force-wrap {
  overflow-wrap: break-word;
}

.circle-delete::before {
  display: inline-block;
  content: '';
  background-repeat: no-repeat;
  background-size: 22px 22px;
  width: 22px;
  height: 22px;
  background-image: url('/assets/img/delete.png');
}

.sm-download-link::before {
  display: inline-block;
  content: '';
  background-repeat: no-repeat;
  background-size: 22px 22px;
  width: 22px;
  height: 22px;
  background-image: url('/assets/img/download-small.png');
}

/* sort css */
.sort-down::after,
.sort-up::after {
  content: ' ';
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 9px 18px;
  vertical-align: text-bottom;
  width: 9px;
  height: 18px;
}

.sort-down::after {
  background-image: url('/assets/img/arrow-down.png');
}

.sort-up::after {
  background-image: url('/assets/img/arrow-up.png');
}

.sort-down:hover::after {
  background-image: url('/assets/img/arrow-down-indigo.png');
}

.sort-up:hover::after {
  background-image: url('/assets/img/arrow-up-indigo.png');
}

/* sort css end */

/* start date picker */
.react-datepicker-popper {
  z-index: 10 !important;
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;

  input:hover {
    cursor: pointer;
  }

  .react-datepicker__input-container {
    width: inherit;
  }
}

label.calendar-icon::after {
  content: '';
  display: inline-block;
  background-image: url('/assets/img/date-picker.png');
  background-repeat: no-repeat;
  background-size: 26px 24px;
  width: 26px;
  height: 24px;
  position: absolute;
  top: 10px;

  &:hover {
    cursor: pointer;
  }
}

label.calendar-icon.form::after {
  top: 55px;
}

label.calendar-icon.from::after {
  right: 29px;
}

label.calendar-icon.to::after {
  right: 14px;
}

/* end date picker */

/* start rich text */
.modal-body .mce-content-body,
.tab-content .mce-content-body,
#detail .mce-content-body,
#timeLineBody .mce-content-body,
#prescreenInfo-tab .mce-content-body,
#tinymce.mce-content-body {
  font-size: $font-size-16;
  font-family: $biotif;
  color: $dark-grey;

  ul li {
    white-space: normal;
  }

  .table {
    font-size: $font-size-16;
    font-family: $biotif;
    color: $dark-grey;
  }

  table {
    border-collapse: collapse;
  }

  table th,
  table td {
    border: 1px solid #c9cbd3;
    padding: 0.4rem;
  }

  strong {
    color: $detail-label;
    font-family: $biotif-bold !important;
  }
}

// @ mentions
.mce-content-body .mention-user[contentEditable=false][data-mce-selected] {
  box-shadow: rgb(0, 101, 255) 0 0 0 1px;
  border-color: transparent;
  background-color: rgb(222, 235, 255);
  color: rgb(66, 82, 110);
}

.mce-content-body .mention-user {
  display: inline;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 0 0.3em 2px 0.23em;
  line-height: 1.714;
  font-size: 1em;
  font-weight: normal;
  word-break: break-word;
  background: rgba(9, 30, 66, 0.08);
  color: rgb(66, 82, 110);
}

.mce-content-body [data-mce-bogus=all] .mention-user {
  display: none;
}

.rt-editor .tox .tox-editor-header {
  z-index: 0;
}

/* end rich text */

/* start - custom scroll bar - chrome, safari */

/* width */
::-webkit-scrollbar {
  //width: 4px;
  //height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $base-white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e3e3e3;
  //border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $field-border;
}

/* end - custom scroll bar */

// toast msg
.Toastify__toast-container {
  .Toastify__toast {
    border-radius: 8px !important;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }

  .Toastify__toast--error {
    border-color: #f5c6cb;
    text-align: center;
    background-color: #f2dede;
    color: #a94442;
    white-space: pre-line;
  }

  .Toastify__close-button {
    color: #721c24;
    font-weight: normal;
    font-size: $font-size-12;
  }
}

// ag grid header height
#detail {
  .multi-view-list {
    #details-title-wrapper #details-title {
      height: 90px;
      padding: var(--ph-y-padding) 14px var(--ph-y-padding) 14px;
    }
  }
}

// global button style
.plus-button::before {
  content: ' ';
  background-image: url('/assets/img/activities/plus-indigo.png');
  background-repeat: no-repeat;
  background-size: 13px 13px;
  vertical-align: middle;
  width: 13px;
  height: 13px;
  padding-left: 20px;
}

.plus-button:hover::before {
  background-image: url('/assets/img/activities/plus-blue.png');
}

// common css
// form and details page
@import './styles/Details.scss';
@import './styles/form.scss';

// list page
@import './compoments/Setting/style.css';
@import './compoments/common/AtsListActions/style.scss';

// modal details/edit/export
@import './compoments/Setting/DetailModal/style.scss';
@import './compoments/Setting/EditModal/style.scss';
@import './compoments/Export/style.scss';
@import './compoments/Tasks/DetailModal/style.scss';
@import './compoments/Tasks/EditModal/style.scss';
